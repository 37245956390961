import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7cd5a46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "item-content white-content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_page_header, {
          title: _ctx.item.title
        }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "main-content text content ql-editor",
              innerHTML: _ctx.item?.description
            }, null, 8, _hoisted_4)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}